import React, { Fragment, ReactElement } from 'react'
import SlickSlider from 'react-slick'
import { Helmet } from 'react-helmet'

import './Slider.scss'

export default function Slider({ photos }: { photos: string[] }): ReactElement {
  return (
    <Fragment>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
      </Helmet>
      <SlickSlider
        dots={false}
        arrows={false}
        fade={true}
        autoplay={true}
        autoplaySpeed={5000}
        infinite={true}
        speed={2000}
        slidesToShow={1}
        slidesToScroll={1}
        pauseOnHover={false}
      >
        {photos.map((photo, index) => (
          <div className="slider__slide" key={index}>
            <div
              className="slider__image"
              style={{ backgroundImage: `url(${photo})` }}
            />
          </div>
        ))}
      </SlickSlider>
    </Fragment>
  )
}
